body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
}
.line {
  fill: none;
  stroke: #fff;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

.widget-layout {
  box-shadow: 0 0px 2px 0px rgb(211 211 211 / 99%);
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #e8e8e8;
  background-color: #fff;
}
.widget-layout:not(:last-child) {
  margin-bottom: 15px;
}
body {
  background-color: #f0f4f7 !important;
}
body::-webkit-scrollbar, body *::-webkit-scrollbar {
  width: 6px;
  height: 6px;      
  margin: 1px;         /* width of the entire scrollbar */
}
body::-webkit-scrollbar-track, body *::-webkit-scrollbar-track {
  background: #f0f4f7;        /* color of the tracking area */
  padding: 2px;
}
body::-webkit-scrollbar-thumb, body *::-webkit-scrollbar-thumb {
  background-color: #c9c9c9;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #c9c9c9;  /* creates padding around scroll thumb */
}
.sidebar-area{
  padding-top: 15px;
  padding-bottom: 10px;
  background-color: #fff;
  box-shadow: 0 0 1px 1px rgba(0,0,0,.1);
}
.sidebar-area, .main-area{
  transition: .3s all ease-in-out;
}
button.toggle-navigation {
  z-index: 99;
  position: fixed;
  padding: 0;
  border: none;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}
@media(max-width: 767px){
  button.toggle-navigation {
    right: 20px;
    bottom: 20px;
  }
  .sidebar-area {
    position: absolute;
    z-index: 999;
    left: 0;
    width: calc(100% - 20px) !important;
    max-width: 240px !important;
    height: 100%;
    bottom: 0;
  }
  div#root:not(.navigation-opened) .sidebar-area {
      left: -250px;
      width: 0px;
      max-width: 0px;
      padding: 0;
  }
  div#root.navigation-opened .sidebar-area {
    left: 0px;
  }
  div#root.navigation-opened .main-area{
    opacity: .4;
    pointer-events: none;
  }
}
@media(min-width: 768px){
  button.toggle-navigation {
    right: 30px;
    bottom: 30px;
  }
  .sidebar-area{
    position: relative;
  }
  div#root.navigation-opened .sidebar-area {
    left: 0%;
  }
  div#root:not(.navigation-opened) .sidebar-area {
    left: -50%;
    width: 0px;
    max-width: 0px;
    padding: 0;
  }
  div#root:not(.navigation-opened) .main-area {
    width: 100%;
    max-width: 100%;
  }
}

.launch-list, .sidebar-area ul.link-list {
  margin-bottom: 0;
  padding-left: 0;
  width: 100%;
  list-style: none;
}
.profile-header {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  box-shadow: 0 0 3px 0 rgba(0,0,0,.2);
}
.profile-header img.platform-logo, .logo-bar img.platform-logo{
  border-radius: 4px;
}

.launch-list .nav-item[status="active"] a{
  color: #d0d0d0;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 600;
  border-radius: 4px;
  background-color: #191919;
}

.sidebar-area ul.link-list li{
  list-style: none;
  line-height: 14px;
  font-size: 12px;
  letter-spacing: .5px;
  display: flex;
  cursor: pointer;
  transition: .3s all ease-in-out;
  color: #222;
  font-weight: 600;
  text-transform: capitalize;
}
.sidebar-area ul.link-list li:not(:last-child){
  padding-bottom: 5px;
}
.sidebar-area ul.link-list li {
  width: 100%;
}
.sidebar-area ul.link-list li a {
  width: 100%;
  padding: 7.5px 10px;
  border-radius: 4px;
  background-color: #f5f5f5;
}
.sidebar-area ul.link-list li a:not(:hover), .sidebar-area ul.link-list li a:not(:focus)_{
  background-color: #f5f5f5;
}
.sidebar-area ul.link-list li.active, .sidebar-area ul.link-list li a:hover, .sidebar-area ul.link-list li a:focus{
  background-color: #000;
  color: #fff;
}
.launch-list .nav-link span.lead-count {
  font-weight: 600;
  font-size: 14px;
}
.launch-list .nav-link{
  margin-bottom: 10px;
}
.launch-list .nav-link{
  border: 1px solid #ccc;
  border-radius:4px ;
  padding: 10px 10px;
  background-color: #fafafa;
  flex-flow: column;
  display: flex;
  font-size: 14px;;
}
.launch-list .nav-link .lead-count {
  text-align: right;
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
}

body, body *, .p-tabview .p-tabview-nav {
  font-family: monospace;
}
.data-table-preview th{
  white-space: nowrap;
}
.data-table-preview td, .data-table-preview th {
  font-size: 12px;
  letter-spacing: .5px;
}
.data-table-preview td.Address1 {
  min-width: 300px;
}
.p-tabview .p-calendar .p-inputtext, .data-table-preview input, .data-table-preview textarea {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
}

.p-editable-column .p-inputtextarea{
  min-width: 300px;
  font-size: 12px !important;
}
.data-table-preview button:not(.p-button-icon-only), .p-tabview-panels .p-button:not(.p-button-icon-only) {
  padding: 5px 10px !important;
  font-size: 10px;
}
.data-table-preview td.time_stamp {
  white-space: nowrap;
}
.p-input-icon-left input.p-inputtext.p-component.small {
  padding-left: 25px;
}
i.pi.pi-search.small {
  font-size: 12px;
  left: 8px;
  top: 16px;
}
.p-paginator-element, .p-dropdown span.p-dropdown-label, .p-paginator .p-paginator-current{
  font-size: 12px !important;
  letter-spacing: .5px;
} 
.p-paginator .p-paginator-current {
  height: 30px;
}
.p-paginator .p-dropdown, .p-paginator-element {
  height: 28px !important;
}
.p-paginator-element {
  font-size: 0px;
  min-width: 30px !important;
  max-width: 2px;
}
.p-dropdown .p-dropdown-trigger svg.p-icon {
  width: 10px;
}
.p-dropdown .p-dropdown-trigger {
  width: 2rem;
}
.p-dropdown span.p-dropdown-label {
  padding: 5px 8px;
  display: inline-flex;
  align-items: center;
}
.p-input-icon-left input.p-inputtext.p-component {
  padding-left: 25px;
}
.p-tabview .p-tabview-nav a.p-tabview-nav-link{
  padding: 10px 15px !important;
  font-weight: 600;
  font-size: 12px;
}
.report-view .p-tabview-panels {
  padding: 0 !important;
}

.field-row .p-inputtextarea, .p-dropdown.p-component span.p-dropdown-label.p-inputtext, .p-editable-column .p-inputtextarea, .p-cell-editing input, .form-theme .field-row select, .form-theme .field-row input, .form-theme .field-row button:not(.p-button-icon-only) {
  width: 100%;
  padding: 7px 10px !important;
  font-family: inherit;
  font-size: 12px !important;
}

.p-dropdown-items {padding: 0 !important;}
.p-dropdown-items .p-dropdown-item {
  padding: 5px 10px !important;
  font-size: 12px;
  border-bottom: 1px solid #ddd !important;
}

.p-cell-editing input {
  min-width: 130px;
}
.field-row .p-component {
  width: 100%;
  float: left;
}
.field-row {
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
  display: inline-block;
}
.field-row label {
  font-size: 12px;
}
.field-row span.info-label {
  position: absolute;
  left: 15px;
  bottom: -8px;
  font-size: 10px;
  opacity: .6;
  font-weight: 600;
}
.recharts-wrapper .recharts-surface g.recharts-layer {
  font-size: 12px;
  letter-spacing: .3px;
}

input.p-inputtext.p-component+button.p-button-icon-only {
  padding: 0;
}

.bg-grey{
  background-color: #f8f9fa;
}
.bg-blue{
  background: #6366F1;
  color: #fff;
}
.bg-white {
  background: #fff;
  color: #000;
}
.status-block {
  padding: 10px 15px;
  border-radius: 6px;
}

@media(min-width: 992px){
  .chart-view{min-height: 400px;}
}
@media(max-width: 991px){
  .chart-view{min-height: 300px;}
  /* .sidebar-area {
    position: fixed;
    bottom: 30px;
    z-index: 99;
    max-width: calc(100% - 30px) !important;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 6px;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.2);
  } */
  form#addcampaign_form {
      margin-bottom: 10px;
  }
}

.custom-tooltip{ background-color: #fff;}
button.p-button[selected-row="0"] { display: none; }
.text-small{
  font-size: 12px;
}
.p-link-text {
  font-weight: 600;
  color: #6366f1;
  cursor: pointer;
}
.form-theme .field-row input::placeholder, .form-theme .field-row .p-placeholder{
  color: #000;
  opacity: .6;
}
.profile-header {
  margin-left: -10px;
  margin-right: -10px;
  width: calc(100% + 20px);
}

.authentication-required {
  height: 100%;
}

.account-header {
  flex-flow: row !important;
}
.view-full-screen { min-height: 100vh; }
.fit-inbody {min-height: 50vh;}
.account-header .nav-item { padding-left: 10px; padding-right: 10px; padding-top: 5px; padding-bottom: 5px; cursor: pointer; letter-spacing: .3px; background-color: #f5f5f5; border-radius: 4px;}
.account-header .nav-item {
  font-size: 12px;
  display: inline-flex;
  gap: 5px;
  align-items: center;
}
.account-header .nav-item:not(:last-child){margin-right: 10px;}
.account-header .nav-item:not(:last-child) { padding-right: 10px; }
.account-link{text-transform: capitalize;}
.user-info .info-row:not(:last-child) {
  /* margin-bottom: 5px; */
  border-bottom: 1px solid #ddd;
}
.user-info .info-row span.info-label {
  font-weight: 600;
  padding-right: 10px;
}
.user-info .info-row {
  padding: 5px 10px;
  background-color: #f5f5f5;
  font-size: 12px;
}

.full-width { width: 100%;}

button.swal-button.swal-button--confirm {
  background-color: #6366F1;
}
button.swal-button.swal-button--confirm:hover{
  background-color: #5c5fff;
}
.swal-footer {
  text-align: center;
}
a.p-button {
  text-decoration: none;
}

td:not(.p-cell-editing)[role=cell] {
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;
  text-overflow: ellipsis;
}

input+.error {
  font-size: 12px;
  color: maroon;
  text-transform: capitalize;
}

span.pill-label {
  border: 1px solid;
  padding: 4px 10px;
  border-radius: 15px;
  text-transform: capitalize;
  font-weight: 600;
}

span.pill-label.non-billable, .label-non-billable, span.pill-label.canceled {
  background-color: maroon;
  border-color: maroon;
  color: #fff;
}
span.pill-label.direct, span.pill-label.billable-by-invoice, .label-billable-by-invoice, span.pill-label.in-progress {
  background-color: #5d8fe1;
  border-color: #5d8fe1;
  color: #fff;
}
.label-billable, span.pill-label.billable, span.pill-label.upwork {
  background-color: #13544e;
  border-color: #13544e;
  color: #fff;
}
span.pill-label.fixed-cost, .label-fixed-cost,  span.pill-label.completed {
  background-color: #ffcb6c;
  border-color: #ffcb6c;
  color: #000;
}